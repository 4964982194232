import { createI18n } from 'vue-i18n'
import Papa from 'papaparse'

const langPath = window.location.protocol + '//' + window.location.host + '/lang/'
const lang = localStorage.getItem('lang') || 'en'
// console.info('- - lang', lang)

const getRow = (arrResult: {}) => {
    let data: string[][] = arrResult as string[][]
    let arr: Record<string, string> = {}

    let rcdRow = []
    for (var i = 0; i < data.length; i++) {
        rcdRow = []
        rcdRow = data[i]

        if (rcdRow) {
            let k1 = rcdRow[0]
            let v1 = rcdRow[1]
            arr[k1] = v1
        }
    }
    return arr
}
  

// - - - - Load en
let arrEn = { message: {} }

const resEn = fetch(langPath + 'en.csv')
    .then((resEn) => resEn.text())
    .then((data) => {

        Papa.parse(data, {
            header: false, // Treat first row as column names
            skipEmptyLines: true,
            complete: (result) => {
                arrEn.message = getRow(result.data)
            }
        })
    })
    .catch((error) => {
        console.info('ERROR: FAILED TO LOAD en.csv', error)
    })
  
  
// - - - - Load chinese simplifed
let arrCh = { message: {} }

const resCh = fetch(langPath + 'zh_cn.csv')
    .then((resCh) => resCh.text())
    .then((data) => {

        Papa.parse(data, {
            header: false, // Treat first row as column names
            skipEmptyLines: true,
            complete: (result) => {
                arrCh.message = getRow(result.data)
            }
        })
    })
    .catch((error) => {
        console.info('ERROR: FAILED TO LOAD zh_cn.csv', error)
    }) 

// - - - - Load malay
let arrMs = { message: {} }

const resMs = fetch(langPath + 'ms.csv')
    .then((resMs) => resMs.text())
    .then((data) => {

        Papa.parse(data, {
            header: false, // Treat first row as column names
            skipEmptyLines: true,
            complete: (result) => {
                arrMs.message = getRow(result.data)
            }
        })
    })
    .catch((error) => {
        console.info('ERROR: FAILED TO LOAD ms.csv', error)
    }) 
    
const messages = {
    en: arrEn,
    zh_cn: arrCh,
    ms: arrMs,
}

const i18n = createI18n({
    legacy: false,
    locale: lang, // default locale
    fallbackLocale: 'en', // fallback locale
    messages,
    globalInjection: true, // allows `$t` globally
})


/* export async function loadLocaleMessages(locale: string) {
  console.info('- - loadLocaleMessages', locale, 'LS', lang)
  const messages = await import(`./${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages.default)
  i18n.global.locale = locale
} */

// legacy: false, // Use Composition API mode
// fallbackLocale: 'en', 

// working


/* const i18n = createI18n({
    locale: "" + lang + "",
    messages: {
      en: {
        signUp: "Sign Up en!!",
        name: "Name",
        LANG_ADDRESS: "Address",
        L_LOGIN: "Login",
        L_FOLDER: "Folder",
        SUPPORT_LBL_DISPLAY_NAME: "Display name"
      },
      bm: {
        signUp: "Register sini!!",
        name: "Nama",
        L_LOGIN: "Log masuk",
        LANG_ADDRESS: "Alamat",
        L_FOLDER: "Fail",
        SUPPORT_LBL_DISPLAY_NAME: "Nama"
      },
      ch: {
        signUp: "账户注册",
        name: "姓名",
        LANG_ADDRESS: "地址",
        L_LOGIN: "登录",
        L_FOLDER: "文件夹",
        SUPPORT_LBL_DISPLAY_NAME: "显示名称"        
      }
    }
}) */

export default i18n

